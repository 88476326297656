import  React from 'react';
import  { useState } from 'react';
import './header.css';
import GatsbyLink from 'gatsby-link';
import logo from '../images/Logo_Transparente_2.png';

const Header = () => {

    const [isNavOpen, setIsNavOpen] = useState(false);
    // const [animateHeader, setAnimateHeader] = useState(false);

    // useEffect(() => {
    //     const listener = () => {
    //         if(window.scrollY > 100){
    //             setAnimateHeader(true);
    //         } else {
    //             setAnimateHeader(false);
    //         }
    //     };
    //     window.addEventListener("scroll", listener);

    //     return () => {
    //         window.removeEventListener("scroll", listener);
    //     }
    // }, []);

    return (
        <header className='w-screen p-6 fixed inset-x-0 top-0 flex flex-row justify-between text-gitman-black bg-gitman-white border-b border-b-gitman-gray'>
            <GatsbyLink to='/' className='self-center transition duration-500 hover:text-gitman-green font-extrabold tracking-widest text-xl'>
                <div className='flex flex-row'>
                    <img className='w-7 mx-4' alt="site logo" src={logo}/>
                    <p>Despensatotal</p>
                </div>
            </GatsbyLink>
            <nav>
                <section className='md:hidden'>
                    <div>
                        <div className={isNavOpen ? 'hidden' : 'visible'}>
                            <button onClick={() => setIsNavOpen((prev) => !prev)}>
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                                </svg>
                            </button>
                        </div>
                        <div className={isNavOpen ? 'visible' : 'hidden'}>
                            <button className='top-1 right-1' onClick={() => setIsNavOpen((prev) => !prev)}>
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <ul className='fixed left-0 h-screen w-screen space-y-5 mt-5 bg-gitman-white bg-opacity-75'>
                                <li>
                                    <GatsbyLink to='/catalog' className='mx-4 text-lg text-gitman-green hover:border-b-2 hover:border-gitman-green'>Catálogo</GatsbyLink>
                                </li>
                                <li>
                                    <GatsbyLink to='/custom' className='mx-4 text-lg text-gitman-green hover:border-b-2 hover:border-gitman-green'>Crea</GatsbyLink>
                                </li>
                                <li>
                                    <GatsbyLink to='/about' className='mx-4 text-lg text-gitman-green hover:border-b-2 hover:border-gitman-green'>Nosotros</GatsbyLink>
                                </li>
                                <li>
                                    <GatsbyLink to='/contact' className='mx-4 text-lg text-gitman-green hover:border-b-2 hover:border-gitman-green'>Contacto</GatsbyLink>
                                </li>
                                <li>
                                    <GatsbyLink to='/faq' className='mx-4 text-lg text-gitman-green hover:border-b-2 hover:border-gitman-green'>FAQ</GatsbyLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                {/* Nav bar showing only for tablets and bigger screens */}
                {/* specify with md that will show  for screens min with 768px screen size*/}
                <ul className='fixed right-0 min-h-screen space-y-5 transform translate-x-full md:relative md:flex md:min-h-0 md:space-y-0 md:min-x-6 md:p-0 md:translate-x-0'>
                    <li>
                        <GatsbyLink to='/catalog' className='mx-4 text-lg hover:border-b-2 hover:border-gitman-green hover:text-gitman-green'>Catálogo</GatsbyLink>
                    </li>
                    <li>
                        <GatsbyLink to='/custom' className='mx-4 text-lg hover:border-b-2 hover:border-gitman-green hover:text-gitman-green'>Crea</GatsbyLink>
                    </li>
                    <li>
                        <GatsbyLink to='/about' className='mx-4 text-lg hover:border-b-2 hover:border-gitman-green hover:text-gitman-green'>Nosotros</GatsbyLink>
                    </li>
                    <li>
                        <GatsbyLink to='/contact' className='mx-4 text-lg hover:border-b-2 hover:border-gitman-green hover:text-gitman-green'>Contacto</GatsbyLink>
                    </li>
                    <li>
                        <GatsbyLink to='/faq' className='mx-4 text-lg hover:border-b-2 hover:border-gitman-green hover:text-gitman-green'>FAQ</GatsbyLink>
                    </li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;