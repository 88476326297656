import React from 'react';
import Header from './header';
import Footer from './footer';
import WhatsappButton from './whatsapp_button';

const Layout = ({ children }) => {
    return (
        <div id='layout-container' className='flex flex-col w-screen min-h-screen justify-between bg-gitman-white font-mono'>
            <Header />
            {children}
            <Footer />
            <WhatsappButton />
        </div>
    );
};

export default Layout;