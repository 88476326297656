import React from 'react';
import FloatingWhatsApp from 'react-floating-whatsapp';
import icon from '../images/fav-icon.png';

export default function WhatsappButton(){

    return (
        <FloatingWhatsApp
                phoneNumber={'+523331042002'}
                accountName={'Canastas de Arale'}
                chatMessage={'Hola! te ayudo con alguna canasta?'}
                avatar={icon}
                className='w-0 h-0'
                 />
    );
}