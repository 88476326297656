import React from 'react';

const Footer = () => {
    return (
        <div>
            <div id='footer' className='flex flex-col pt-5 w-screen border-t border-t-gitman-gray md:flex-row inset-x-0 bottom-0 gap-4'>
                <div className='w-screen md:w-[20%] lg:w-1/3 mx-4 items-center'>
                    <p className='text-base text-center block'>Despensa Total</p>
                    <p className='text-base text-center block'>by Clinica del Click ®</p>
                </div>
                <div className='w-screen md:w-[60%] lg:w-1/3 flex flex-col items-center gap-2'>
                    <p className='text-sm font-black'>Contactanos</p>
                    <div className='flex flex-col gap-2 lg:flex-row lg:gap-10'>
                        <div className='flex flex-row'>
                            <div className='flex flex-col justify-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#1D560F" className="w-6 h-6 mr-3">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                                </svg>
                            </div>
                            <div className='flex flex-col gap-1'>
                                <p className='text-sm font-normal'>Teléfono</p>
                                <p className='text-sm font-light italic'>3331042002</p>
                            </div>
                        </div>
                        <div className='flex flex-row'>
                            <div className='flex flex-col justify-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#1D560F" className="w-6 h-6 mr-3">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                </svg>
                            </div>
                            <div className='flex flex-col gap-1'>
                                <p className='text-sm font-normal'>Correo</p>
                                <p className='text-sm font-light italic'>ventas@despensatotal.com</p>
                                <p className='text-sm font-light italic'>hola@clinicadelclick.com</p>
                            </div>
                        </div>
                        <div className='flex flex-row'>
                            <div className='flex flex-col justify-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#1D560F" className="w-6 h-6 mr-3">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                                </svg>
                            </div>
                            <div className='flex flex-col gap-1'>
                                <p className='text-sm font-normal'>Dirección</p>
                                <p className='text-sm font-light italic'>Guadalajara Jalisco</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-screen md:w-[20%] lg:w-1/3 flex flex-col items-center gap-2 mb-4'>
                    <p className='text-sm font-black'>Siguenos</p>
                    <div className='flex flex-row gap-6 md:gap-2'>
                        <a href="https://tiktok.com/@despensatotal">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-10" fill="#1D560F" viewBox="0 0 50 50" >
                                <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z"></path>
                            </svg>
                        </a>
                        <a href="https://instagram.com/despensatotal?igshid=YmMyMTA2M2Y=">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-10" fill="#1D560F" viewBox="0 0 50 50">
                                <path d="M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z"></path>
                            </svg>
                        </a>
                        <a href="https://instagram.com/despensatotal?igshid=YmMyMTA2M2Y=">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-10" fill="#1D560F" viewBox="0 0 50 50" >
                                <path d="M 16 3 C 8.83 3 3 8.83 3 16 L 3 34 C 3 41.17 8.83 47 16 47 L 34 47 C 41.17 47 47 41.17 47 34 L 47 16 C 47 8.83 41.17 3 34 3 L 16 3 z M 37 11 C 38.1 11 39 11.9 39 13 C 39 14.1 38.1 15 37 15 C 35.9 15 35 14.1 35 13 C 35 11.9 35.9 11 37 11 z M 25 14 C 31.07 14 36 18.93 36 25 C 36 31.07 31.07 36 25 36 C 18.93 36 14 31.07 14 25 C 14 18.93 18.93 14 25 14 z M 25 16 C 20.04 16 16 20.04 16 25 C 16 29.96 20.04 34 25 34 C 29.96 34 34 29.96 34 25 C 34 20.04 29.96 16 25 16 z"></path>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
            {/* <div className='w-screen'>
                <p className='text-xs text-center'>©Arale Baskets @esalcido 2022</p>
            </div> */}
        </div>
    );
};

export default Footer;