import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';

const Faq = () => {
    return (
        <Layout>
            <div id='faq container' className='pt-32 flex flex-col items-center w-screen'>
                <h2 className='text-4xl text-center'>FAQ</h2>
                <div className='p-6 mt-10 w-4/5 md:w-1/2 md:mt-4'>
                    <p className='text-lg text-center tracking-normal leading-5 font-bold'>¿En dónde se encuentran ubicados?</p>
                    <p className='mt-2 text-lg text-center tracking-normal leading-5'>Nos encontramos ubicados en la ciudad de Guadalajara, jalisco. Muy cerca de la zona centro.</p>
                </div>
                <div className='p-6 mt-10 w-4/5 md:w-1/2 md:mt-4'>
                    <p className='text-lg text-center tracking-normal leading-5 font-bold'>¿Puedo pasar por mis despensas?</p>
                    <p className='mt-2 text-lg text-center tracking-normal leading-5'>En caso de que lo requieras y sea viable para ti, podrías pasar por los productos sin ningún
problema a nuestras instalaciones.</p>
                </div>
                <div className='p-6 mt-10 w-4/5 md:w-1/2 md:mt-4'>
                    <p className='text-lg text-center tracking-normal leading-5 font-bold'>¿Realizan envíos?</p>
                    <p className='mt-2 text-lg text-center tracking-normal leading-5'>Tenemos cobertura nacional por lo que podemos enviar las despensas a cualquier lugar de
México, siempre priorizando la mejor opción de paquetería para ti. Teniendo como finalidad que
no tengas ninguna preocupación.</p>
                </div>
                <div className='p-6 mt-10 w-4/5 md:w-1/2 md:mt-4'>
                    <p className='text-lg text-center tracking-normal leading-5 font-bold'>¿Emiten factura fiscal?</p>
                    <p className='mt-2 text-lg text-center tracking-normal leading-5'>Si, todos nuestros productos pueden ser facturados.</p>
                </div>
                <div className='p-6 mt-10 w-4/5 md:w-1/2 md:mt-4'>
                    <p className='text-lg text-center tracking-normal leading-5 font-bold'>¿Puedo hacer cambios en los productos?</p>
                    <p className='mt-2 text-lg text-center tracking-normal leading-5'>Contamos con la opción de personalización y crear tu propia despensa, por lo que además de
adaptarnos a tu presupuesto, puedes escoger la cantidad de productos que quieres que contenga,
siempre tendremos como prioridad mantener la calidad en nuestros productos.</p>
                </div>
                <div className='p-6 mt-10 w-4/5 md:w-1/2 md:mt-4'>
                    <p className='text-lg text-center tracking-normal leading-5 font-bold'>¿Cuánto tiempo tardan en entregar el total de mi pedido?</p>
                    <p className='mt-2 text-lg text-center tracking-normal leading-5'>Dependerá mucho de la cantidad que estés buscando comprar, pero ten la seguridad de que
siempre lo haremos en el menor tiempo posible. Todos nuestros pedidos son atendidos de forma
personalizada, buscando con ello dar la mejor atención en cada etapa de tu pedido.</p>
                </div>
                <div className='p-6 mt-10 w-4/5 md:w-1/2 md:mt-4'>
                    <p className='text-lg text-center tracking-normal leading-5 font-bold'>¿Pueden personalizar mis despensas?</p>
                    <p className='mt-2 text-lg text-center tracking-normal leading-5'>Gracias a la modalidad de crea tu despensa podemos hacer con ella lo que tu necesites. Desde
integrar tu logo en cada una de las cajas, agregar un mensaje personalizado o lo que tu necesites.</p>
                </div>
                <div className='p-6 mt-10 mb-10 w-4/5 md:w-1/2 md:mt-4'>
                    <p className='text-lg text-center tracking-normal leading-5 font-bold'>¿Cuál es el mínimo de compra?</p>
                    <p className='mt-2 text-lg text-center tracking-normal leading-5'>No tenemos mínimo de compra, si deseas comprar desde una pieza es posible, ya sea que decidas
pasar por ella o la enviemos a un lugar en específico.</p>
                </div>
            </div>
        </Layout>
    );
};

export default Faq;

export function Head() {
    return(
      <Seo />
    );
  }